// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@use '../node_modules/@angular/material/index' as mat;
@import "leaflet/dist/leaflet.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Dashboard-primary: mat.define-palette(mat.$blue-palette);
$Dashboard-accent: mat.define-palette(mat.$red-palette);


// The warn palette is optional (defaults to red).
$Dashboard-warn: mat.define-palette(mat.$orange-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Dashboard-theme: mat.define-light-theme((
  color: (
    primary: $Dashboard-primary,
    accent: $Dashboard-accent,
    warn: $Dashboard-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Dashboard-theme);


// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the default theme (same as the example above).
$primary: $Dashboard-primary;
$accent:  $Dashboard-accent;
$warn:  $Dashboard-warn;
$apptheme:   mat.define-dark-theme($primary, $accent);

// Include the default theme styles.
@include mat.all-component-themes($apptheme);

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 700;
  src: url('assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

/* Material Icons*/
/* Source: https://google.github.io/material-design-icons/*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('assets/fonts/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
    url('assets/fonts/iconfont/MaterialIcons-Regular.woff') format('woff'),
    url('assets/fonts/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}



// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Lato',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 400)
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($custom-typography);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);


// Override the typography in the core CSS.
@include mat.core($custom-typography);



html {
  background-color: rgba(0, 0, 0, 0.87);  
}

body {
  margin: 0;    
  font-family: Lato, sans-serif;
  
}


 
  .container {
    max-width: 1000px;
    min-height: 100vh;
    margin: auto;
    padding: 1.2rem;
    display: flex;
    padding-bottom: 5rem;    
  }
  
 

.mat-card {
  border-color: black;
  margin: 5px;    
  @include mat.elevation(8); 
}

.label {
  text-align: center;
  vertical-align: center;
  cursor:pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.mat-toolbar {
    background: #fafafa00;
}

.mat-card-avatar {
 height: 140px; 
 width:140px;
}
.zilogo {
  width: 50px;
  margin: 5%;
}

.primarybg {
  background-color: mat.get-color-from-palette($primary) ;
  color: white;
  }


.darkbg {
background-color: mat.get-color-from-palette($primary, darker) ;
color: white;
}

.greybg {
  background-color: rgb(230,230,230) ;
  color: black;
  }

.lightbg {
  background-color: mat.get-color-from-palette($primary, 100) ;
  color: mat.get-color-from-palette($primary, 900) ;
  }

  .image404 {
    border-radius: 50%;
    background-image: linear-gradient(
      rgba(25,118,210,.15),
    rgba(25,118,210,0.15)),
    url(https://ziwebstorage.blob.core.windows.net/appradar/assets/beach-blur-car-colors-386000.jpg);
    background-size: cover;
    background-position: center;
    padding: 3rem;
    width: 250px;
    height: 250px;
}  

.thespinner {
  width: 125px;
  height: 125px;
}


.primarycolor {
  color: mat.get-color-from-palette($primary) !important;
}

.accentcolor {
  color: mat.get-color-from-palette($accent) !important;
}

.greycolor {
  color: darkgrey !important;
}

.mobileheading {
  font-size: 20px!important;
}

.lightgreycolor {
  color: grey !important;
}

.padding {
padding:.5rem;
}

.fullpadding {
  padding:1rem;
  }
a,a:visited {
  color: mat.get-color-from-palette($primary) ;
}

a:hover{
  text-decoration:underline;
}
.full-width {
  width: 100%;
}

.linkcursor {
  cursor:pointer ;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.green {
  
  background-color: greenyellow;
  color: black;
}

.yellow {
  background-color: orange;
  color: black;

}

.red {
  background-color: crimson;
  color: black;
}

.errortext {
  color: crimson;  
}

.add-margin {
  margin:1em;
}

// Leaflet
.leaflet-container{
  font:inherit;
}

.bwbackgroundmap {
  filter:grayscale(100%);
}

.customlegend, .maphoverinfo {
  background-color: rgba(255, 255, 255, 0.6);
  padding:1rem;
  border-radius: 15px;
}

.maphoverinfo {
  position:relative; 
  float: left;   
}

.custombgmap {
  filter:grayscale(100%);
}




  .mat-form-field-appearance-outline .mat-form-field-outline {
     background-color: white !important;
  }
  
  .no-padding {
    padding:0px !important;
  }

  .overlayinfobox {
    position: absolute;
    background-color: #444444;
    margin-bottom: 1rem;
    z-index: 100;
    padding: 2rem;
    margin: 2rem!important;
    border-radius: 10px;
    border: 1px;
    border-color: #262626;
    border-style: solid;
  }